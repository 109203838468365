.profile {
    display: block;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #fa323f;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #333; /* Added background color to make white text visible */
}

.profile h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fa323f;/* Change the text color to red */
}

.profile input[type="file"] {
    display: block;
    margin-top: 10px; 
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 4px;
}

.profile button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile button:disabled {
    background-color: #aaa;
}

.profile button:hover:not(:disabled) {
    background-color: #0056b3;
}

.profile input[type="inputDisplayName"] {
    padding: 8px;
    width: 100%;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.profile img[type="imageAvatar"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    border-radius: 8px;
    border: 2px solid #fa323f;   
}

#close {
    float:right; 
    padding:2px 5px;
    background:#ccc;   
}