.ContentDisplay{
    background: rgb(166, 39, 39);
    width: 100%;
    display: flex;
    flex-direction: column;
}
.JoinedDisplay{
    
    color: #fff;
    
}