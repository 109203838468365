.proposal__list {
    text-decoration: none;
    color: rgba(255,255,255, 0.8);
    background: black;
    padding: 5px;
    border-radius: 4px;
    font-weight: normal;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    max-width: 425px;
    border: 2px solid #fa323f; /* Set the border color to salmon */
}

.proposal__list:hover {
    color: #fa323f;
}

.proposal__listselected {
    text-decoration: none;
    color: rgba(255,255,255, 0.8);
    background: rgb(166, 39, 39);
    padding: 5px;
    border-radius: 4px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    max-width: 425px;
    border: 2px solid #fa323f; /* Set the border color to salmon */
}

.proposal__listselected:hover {
    color: #fa323f;
}
