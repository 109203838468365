/* General text color */
body {
    color: #fff; /* Sets default text color to white */
}

.main__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
}

.main__navbar-links {
    flex: 1;
    display: flex;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-avatar {
    width: 80px; /* Adjusted size */
    height: 80px; /* Adjusted size */
    border-radius: 50%;
    border: 2px solid #fa323f; /* Salmon color border */
    margin-right: 10px; /* Adjust spacing between avatar and text */
}

.user-info-text {
    display: flex;
    flex-direction: column;
}

.user-displayname,
.user-profile-name {
    color: white;
    font-size: 16px; /* Adjusted font size */
    font-weight: bold; /* Bold text */
}

.user-profile-name {
    font-size: 14px; /* Slightly smaller font size for profile name */
}

.main__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.link_to_new_proposal {
    display: flex;
    text-align: right;
}

.main__navbar-sign {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.settings-icon {
    margin-left: 10px;
    font-size: 24px;
    color: #fa323f; /* Salmon color */
    cursor: pointer;
}

.popup-menu {
    background-color: white;
    border: 1px solid #fa323f; /* Salmon color */
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
}

.popup-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.popup-menu li {
    padding: 8px 12px;
    cursor: pointer;
    color: black;
}

.popup-menu li:hover {
    background-color: white;
    border-left: 4px solid #fa323f; /* Salmon color */
}

.main__navbar-links_container p,
.main__navbar-sign p,
.main__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
