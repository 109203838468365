.person {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.profile-picture {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #000; /* Change color and width as needed */
}
