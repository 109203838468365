.friend__list-container{
        
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #fff;

}
.friend__list-container p
{
    color: #fff;
    font-family:  var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
   
}
li {
    list-style-type: none;
}