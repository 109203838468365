.circle {
    display: flex;
    border-radius: 50%;
   background-color: purple;
   opacity: 0.4;
   
  }
  
  .text {
    margin: auto;
  }

  .city-name {
    color: white;
  }
  