/* Navbar Styles */
.side__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    max-width: 500px;
}

.side__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 500px;
}

.side__navbar-links_logo {
    margin-right: 2rem;
}

.side__navbar-links_logo img {
    width: 62px;
    height: 62px;
}

.side__navbar-links_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
}

.side__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.side__navbar-links_container p,
.side__navbar-sign p,
.side__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

/* Proposal List Styles */
.proposal__list,
.proposal__listselected {
    border: 2px solid #fa323f; /* Salmon border color */
    text-decoration: none;
    color: rgba(255,255,255, 0.8);
    background: black;
    padding: 5px;
    border-radius: 4px;
    font-weight: normal;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    max-width: 425px;
}

/* Hover and Selected States */
.proposal__list:hover {
    color: #fa323f;
}

.proposal__listselected {
    background: rgb(166, 39, 39);
    font-weight: bold;
}

.proposal__listselected:hover {
    color: #fa323f;
}
