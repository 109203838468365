.voting-sort {
    padding: 20px;
    background: #f0f0f0;
    border-radius: 8px;
}

.voting-sort h2 {
    margin-bottom: 20px;
}

.voting-sort ul {
    list-style: none;
    padding: 0;
}

.voting-sort li {
    padding: 10px;
    margin-bottom: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}
